import { useBreakpoints, useElementBounding } from '@vueuse/core';
import { useElementTransform } from '@vueuse/motion';

export const useScrollAnimations = () => {
    const breakpoints = useBreakpoints({
        'xs': 320, // Modern phones portrait
        'sm': 576, // Modern phones landscape
        'md': 768, // Tablet / medium devices
        'lg': 992, // Desktop
        'xl': 1260, // High res - large desktop
        'xxl': 1700 // Mega large desktop
    });
    function parallax(element, options = {}) {
        if (!toValue(element) || !toValue(options?.trigger) || !breakpoints.greater('md')?.value) {
            return;
        }

        const { top: triggerTop, bottom: triggerBottom, height: triggerHeight } = useElementBounding(options.trigger);

        const { transform } = useElementTransform(element);

        const adjustedY = computed(() => {
            const adjusted = triggerHeight.value - triggerBottom.value + (triggerTop.value * (options.offset || .5));

            if (options?.direction === 'down' && adjusted < 0) {
                return 0;
            }

            return adjusted;
        });

        transform.y = adjustedY || null;
    }

    return { parallax };
};
